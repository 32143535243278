<template>
    <div class="conferma" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'phone' : ''">
        <v-alert v-if="formSuccess" ref="alert" class="alert animate__animated animate__fadeInRight" type="success">Form compilato con successo</v-alert>
        <v-alert v-if="formError" ref="alert" class="alert animate__animated animate__fadeInRight" type="error">Impossibile inviare il form non sei sulla lista degli invitati</v-alert>
        <div class="cntConferma">
            <div class="cntTitolo">
                <img class="imgFlowerConferma" src="../assets/fiori commenti.png" />
                <div>
                    <div class="stringUnisciti">Conferma la tua presenza</div>
                    <div class="stringConferma">Il nostro giorno non sarebbe lo stesso senza di voi, vi aspettiamo!</div>
                </div>
                <img class="imgFlowerConferma sx" src="../assets/fiori commenti.png" />
            </div>

            <v-form ref="form">
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="fields.nome" :rules="[rules.required]" label="Nome" outlined clearable @input="setFields"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field v-model="fields.cognome" :rules="[rules.required]" label="Cognome" outlined clearable @input="setFields"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="fields.numInvitati"
                                hint="Numero di tutti i componenti del nucleo familiare che verranno al ricevimento"
                                :rules="[rules.required, rules.maxValue]"
                                label="Numero Invitati"
                                outlined
                                clearable
                                @input="changeNumPartecipanti"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" v-for="(invitato, index) in invitati" :key="index">
                            <v-text-field v-model="invitati[index]" :rules="[rules.required]" :label="'Nome e Cognome invitato ' + (index + 1)" outlined clearable></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea
                                class="commenti"
                                :class="{ textfield: fields.commenti }"
                                hint="Comunicare allergie e intolleranze"
                                v-model="fields.commenti"
                                label="Commenti"
                                outlined
                                clearable
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            <div class="cntBtns" v-if="loading">
                <v-progress-circular style="margin: 12px" :size="30" :width="4" color="black" indeterminate></v-progress-circular>
            </div>
            <div class="cntBtns" v-else>
                <v-btn class="" color="primary" @click="cancellaCampi">Cancella Campi</v-btn>
                <v-btn class="btnInvia" color="success" @click="addRow">Invia</v-btn>
            </div>
            <p class="tempiConferma">La conferma è gradita entro e non oltre il 16 Giugno 2022.</p>
        </div>
        <img class="imgFiore conf" src="../assets/green-flower.png" />
    </div>
</template>

<script>
import googleSheets from "../scripts/googleSheets";
export default {
    data() {
        return {
            rules: {
                required: value => !!value || "Il campo è obbligatorio",
                maxValue: value => (value && value < 11) || "Non puoi inserire più di 10 partecipanti"
            },
            fields: {
                nome: "",
                cognome: "",
                numInvitati: null,
                nomiInvitati: "",
                commenti: ""
            },
            formSuccess: false,
            formError: false,
            invitati: [],
            loading: false
        };
    },

    methods: {
        changeNumPartecipanti() {
            this.invitati = [];
            if (this.fields.numInvitati > 10) {
                return;
            }
            for (var i = 1; i <= this.fields.numInvitati; i++) {
                this.invitati.push("");
            }
        },

        async addRow() {
            var validate = this.$refs.form.validate();
            if (validate) {
                this.loading = true;
                this.fields.nomiInvitati = this.invitati.join(", ");
                var res = await googleSheets.addRow(this.fields);
                if (!res) this.formError = true;
                else this.formSuccess = true;
                this.loading = false;
                setTimeout(() => {
                    this.$refs.alert.$el.classList.remove("animate__fadeInRight");
                    this.$refs.alert.$el.classList.add("animate__fadeOutRight");
                    setTimeout(() => {
                        this.formSuccess ? (this.formSuccess = false) : (this.formError = false);
                        this.$refs.alert.$el.classList.remove("animate__fadeInRight");
                        this.$refs.alert.$el.classList.add("animate__fadeOutRight");
                    }, 500);
                }, 4000);
            }
        },

        async setFields() {
            var row = await googleSheets.getRow(this.fields, 0);
            if (row) {
                this.fields.numInvitati = row["Numero Invitati"];
                this.fields.commenti = row["Commenti"];
                var array = row["Nomi Invitati"].split(", ");
                this.invitati = array;
            }
        },

        cancellaCampi() {
            this.fields = {
                nome: "",
                cognome: "",
                numInvitati: "",
                nomiInvitati: "",
                commenti: ""
            };
            this.invitati = [];
        }
    }
};
</script>

<style lang="scss">
.conferma {
    background: linear-gradient(180deg, rgb(129, 148, 255) 0%, rgb(166, 198, 245) 35%, rgb(191, 224, 255) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    .alert {
        position: fixed;
        right: 4px;
        top: 95px;
        z-index: 999;
    }
    .cntConferma {
        width: 60rem;
        //background-color: #fffff0;
        //border-radius: 20px;
        //box-shadow: 0 2px 10px -3px #000;
        text-align: center;
        margin: 7.5rem 0;
        display: flex;
        flex-direction: column;
        .cntTitolo {
            display: flex;
            align-items: center;
            .stringUnisciti {
                font-family: "Alex Brush", cursive !important;
                font-size: 40px;
                font-weight: bold;
            }
            .stringConferma {
                font-size: 18px;
                margin: 0 0 40px;
            }
            .imgFlowerConferma {
                width: 30%;
            }
            .sx {
                transform: scale(-1, 1);
            }
        }

        .col {
            padding: 12px 12px 0;
        }
        .commenti {
            .v-input__slot,
            .v-text-field__details {
                margin-bottom: unset !important;
            }
        }
        .v-input__slot {
            background-color: white !important;
            .v-text-field__slot {
                textarea,
                input {
                    font-family: "Times New Roman", Times, serif;
                }
            }
        }
        .v-text-field__details {
            padding: unset !important;
            .v-messages.theme--light.error--text {
                color: white !important;
            }
        }
        .cntBtns {
            display: flex;
            justify-content: flex-end;
            .btnInvia {
                //margin-bottom: 20px;
                width: 6rem;
                margin: 0 12px;
                align-self: flex-end;
            }
        }
        .tempiConferma {
            margin-top: 30px;
        }
    }
    .imgFiore.conf {
        margin-top: 0 !important;
    }
}

.conferma.phone {
    background-size: unset;
    .cntConferma {
        width: unset;
        height: unset;
        margin: 70px 1rem;
        .cntTitolo {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .imgFlowerConferma {
                align-self: flex-start;
            }
            .stringUnisciti {
                font-size: 27px;
                line-height: 22px;
            }
            .stringConferma {
                margin: 15px 0;
                font-size: 12px;
            }
        }
        .cntBtns {
            justify-content: center;
        }
        .tempiConferma {
            font-size: 13px;
        }
    }
}
</style>
